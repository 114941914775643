
.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

.App-alert {
  margin: 50px 50px 20px 50px;
}

.App-logo {
  width: 98%;
}

@media screen and (min-width: 1150px) {
  .App-logo {
    /* padding: 10px; */
    width: 1125px;
  }
}

.App-datasets {
  margin: 20px;
}

.App-body {
  margin: 20px;
  /* font-size: calc(1px + 1.5vmin); */
  color:rgb(39, 46, 45)
}

.App-header {
  margin-left: 20px;
  font-size: calc(10px + 2vmin);
  color: #24763C
}

.App-header2 {
  margin: 30px 20px 30px 20px;
  font-size: calc(10px + 2vmin);
  color: #24763C
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.desc-header {
  font-size: 1.2em;
}

.desc-button {
  margin: 0px 20px 0px 10px;
}

.app-footer {
  /* position: fixed; */
  /* bottom: 20px; */
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  /* font-weight: 300; */
  color:rgb(92, 106, 104);
}

.bottom-space {
  padding-bottom: 10px;
}